<template>
  <div id="quick-access">
    <el-container class="fyu-appcontainer">
      <el-main class="">
        <p class="title">
          设置快捷入口<span class="sub-title">（可设置3-8个，拖动可排序）</span>
        </p>
        <div class="card-container">
          <div class="picked-card-box">
            <draggable
              v-model="pickedList.details"
              class="picked-card-list"
              ghost-class="picked-card-ghost"
              group="pickedCard"
              animation="200"
              @start="drag = true"
              @end="drag = false"
            >
              <transition-group class="picked-card-container" >
                <div
                  
                  class="picked-card-item"
                  :class="{ 'picked-card-draggable': item.draggableFlag }"
                  v-for="(item, index) in pickedList.details"
                  :key="item.requestId"
                  @mouseenter="item.draggableFlag = true"
                  @mouseleave="item.draggableFlag = false"
                >
                  <img :src="quickAccessIcon[item.optionImg]" class="picked-card-icon" />
                  <p class="picked-card-name">{{ item.configName}}</p>
                  <i
                    class="picked-card-del-btn"
                    v-show="item.draggableFlag"
                    @click="removeCard(item, index)"
                  ></i>
                  <i
                    class="picked-card-drag-icon"
                    v-show="item.draggableFlag"
                  ></i>
                </div>
              </transition-group>
            </draggable>
            <div class="quick-access-btns">
              <div class="back-btn" @click="backWorkbench">返回</div>
              <div class="save-btn" @click="saveCard">保存</div>
            </div>
          </div>
          <div class="all-card-list">
            <div
              class="card-modules"
              v-for="(item, index) in allList"
              :key="item.module"
            >
              <p class="title">
                {{ item.module }}
              </p>
              <div class="card-module-list">
                <div
                  class="card-item"
                  :class="{
                    'card-item-hovering':
                      moduleItem.hovering && !moduleItem.isHas,
                    'card-item-picked': moduleItem.isHas,
                  }"
                  v-for="(moduleItem, moduleIndex) in item.details"
                  :key="moduleItem.requestId"
                  @mouseenter="moduleItem.hovering = true"
                  @mouseleave="moduleItem.hovering = false"
                  @click="pickCard(index, moduleIndex, moduleItem)"
                >
                  <img :src="quickAccessIcon[moduleItem.optionImg]" class="card-item-icon" />
                  <i class="add-icon"></i>
                  <p class="card-item-name">{{ moduleItem.configName }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {GetAlreadyConfigured,PostSaveWorkConfigs} from "@/api/index.js"
export default {
  name: "quickaccess",
  components: {  draggable },
  data() {
    return {
             quickAccessIcon: [
        require("@/assets/img/KJ0.png"),
        require("@/assets/img/KJ1.png"),
        require("@/assets/img/KJ2.png"),
        require("@/assets/img/KJ3.png"),
        require("@/assets/img/KJ4.png"),
        require("@/assets/img/KJ5.png"),
        require("@/assets/img/KJ6.png"),
        require("@/assets/img/KJ7.png"),
        require("@/assets/img/KJ8.png"),
        require("@/assets/img/KJ9.png"),
        require("@/assets/img/KJ10.png"),
        require("@/assets/img/KJ11.png"),
        require("@/assets/img/KJ12.png"),
        require("@/assets/img/KJ13.png"),
        require("@/assets/img/KJ14.png"),
        require("@/assets/img/KJ15.png"),
        require("@/assets/img/KJ16.png"),
        require("@/assets/img/KJ17.png"),
        require("@/assets/img/KJ18.png"),
        require("@/assets/img/KJ19.png"),
        require("@/assets/img/KJ21.png"),
        require("@/assets/img/KJ22.png"),
        require("@/assets/img/KJ23.png"),
        require("@/assets/img/KJ24.png"),
        require("@/assets/img/KJ25.png"),
      ],
      pickedList: [],
      allList: [],
    };
  },
  methods: {
    async getDate() {
      let resPickedList = await GetAlreadyConfigured("quickEnter");
      let resAllList =resPickedList.data.slice(1)
      if (resPickedList.status == 200 ) {
        for (let obj of resPickedList.data[0].details) {
          obj.draggableFlag = false;
        }
        this.pickedList = resPickedList.data[0];
        this.manageData(resAllList);
      } else {
        this.$message.error(resPickedList.statusText);
      }
    },
    manageData(allList) {
      // 初始化所有卡片hovering状态, picked状态
      for (let i = 0; i < allList.length; i++) {
        let ModularOptionList = allList[i].details;
        for (let j = 0; j < ModularOptionList.length; j++) {
          let ModularOption = ModularOptionList[j];
          ModularOption.hovering = false;
          let id = ModularOption.requestId;
          for (let k = 0; k < this.pickedList.length; k++) {
            if (id == this.pickedList[k].requestId) {
              ModularOption.isHas = true;
            }
          }
        }
      }
      this.allList = allList;
    },
    // 移除按钮
    removeCard(item, index) {
         if (this.pickedList.details.length <= 3) {
        this.$message({
          message: "不能更少了……再添加一些吧！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let id = item.requestId;
      for (let i = 0; i < this.allList.length; i++) {
        let ModularOptionList = this.allList[i].details;
        for (let j = 0; j < ModularOptionList.length; j++) {
          let ModularOption = ModularOptionList[j];
          if (id == ModularOption.requestId) {
            ModularOption.isHas = false;
            this.pickedList.details.splice(index, 1);
            return;
          }
        }
      }
    },

    pickCard(index, moduleIndex, moduleItem){
      if (moduleItem.isHas) return;
      if (this.pickedList.details.length >= 8) {
        this.$message({
          message: "设置已满，删除一些后再试试吧！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      
      let obj = {
        configName: moduleItem.configName,
        hovering: moduleItem.hovering,
        draggableFlag:moduleItem.draggableFlag,
        isHas:moduleItem.isHas,
        optionImg: moduleItem.optionImg,
        requestId: moduleItem.requestId,
      };
      this.pickedList.details.push(obj);
      this.allList[index].details[moduleIndex].isHas = true;
    },

      // 点击保存提交
     async saveCard(){
      let arr=this.pickedList.details
      let ids=[]
      for (let i = 0; i <arr.length; i++) {    
         ids.push(arr[i].requestId)
      }
      let obj={
        ids:ids,
        modularType:"quickEnter"
      }
      const res=await PostSaveWorkConfigs(obj)
      if(res.data){
           this.$message({
          message: "保存设置成功",
          type: "success",
          showClose: true,
        });
        this.getDate()
      }
    },
    backWorkbench() {
      this.$router.push("/");
    },
  },
  mounted() {
    this.getDate();
    
  },
};
</script>

<style scoped>
@import url("../../assets/css/workbench/quickAccess.css");
#quick-access {
  background: rgba(244, 245, 249, 1);
  height: 100%;
}
.fyu-appcontainer {
  height: 100%;
}
</style>