<template>
  <div class="echartSetting" style="height: 100%">
    <el-row :gutter="20" style="height: 100%">
      <el-col :span="12" style="height: 100%">
        <div class="settingBox">
          <p class="header">设置常用统计图表 
            <span>（最少设置2个）</span>
          </p>
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in settingArr" :key="index">
              <div class="iconSetting">
                <img :src="img19" alt="">
                <i class="el-icon-remove" @click="removeMeth(item,index)"></i>
                <span>{{item.optionName}}</span>
              </div>
            </el-col>
          </el-row>
          <el-button class="one-button" @click="save(1)">保存</el-button>
          <el-button class="two-button" @click="save(0)">取消</el-button>
        </div>
      </el-col>
      <el-col :span="12" style="height: 100%">
        <div class="settingBox">
          <p class="header">全部图表预览</p>
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in noSettingArr" :key="index">
              <div class="iconSetting iconRight">
                <img :src="img19" alt="">
                <i class="el-icon-circle-plus" @click="addMethod(item,index)"></i>
                <span>{{item.optionName}}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  WelfareConfigSetData, WelfareConfigSet
} from "@/api/index";
export default {
  name: 'MoreSetting',
  data() {
    return {
      img19: require("@/assets/img/iconSetting.png"),
      settingArr: [],
      noSettingArr: [],
    }
  },
  mounted() {
    this.WelfareConfigSetData()
  },
  methods: {
    async save() {
      let ids = []
      this.settingArr.forEach(item => {
        ids.push(item.id)
      })
      let data = await WelfareConfigSet(ids);
      console.log('保存福利工作台配置')
      console.log(data)
      this.$router.push("/Welfare");
    },
    async WelfareConfigSetData() {
      let data = await WelfareConfigSetData();
      console.log('获取福利已配置按钮')
      console.log(data)
      this.settingArr = data.data.已配置
      this.noSettingArr = data.data.未配置
    },
    removeMeth(item,index) {
      if(this.settingArr.length > 2) {
        this.settingArr.splice(index, 1)
        this.noSettingArr.push(item)
      } else {
        this.$message({
          message: "常用统计图表最少设置2个",
          type: "warning",
          showClose: true,
        });
      }
    },
    addMethod(item,index) {
      this.noSettingArr.splice(index, 1)
      this.settingArr.push(item)
    },
  }
}
</script>

<style scoped>
  .settingBox {
      height: 100%;
      padding: 30px;
      text-align: left;
      background: #fff;
      box-sizing: border-box;
    }
    .settingBox .header {
      margin-bottom: 24px;
      padding-bottom: 10px;
      border-bottom: 1px solid #EDF0FC;
    }
    .settingBox .header span {
      font-size: 14px;
      color: #909399;
    }
    .settingBox .one-button {
      background: #FF7E00;
      color: #fff;
      border: 1px solid #FF7E00;
    }
    .settingBox .two-button:hover {
      background: #fff;
      color: #FF7E00;
      border: 1px solid #FF7E00;
    }
    .settingBox .iconSetting {
      position: relative;
      display: inline-block;
      width: 188px;
      height: 90px;
      margin-bottom: 20px;
      padding: 0 20px 0 80px;
      font-size: 14px;
      box-sizing: border-box;
      border: 1px solid #DCDFEB;
    }
    .iconSetting img {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      width: 48px;
    }
    .iconSetting span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .iconSetting i {
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 20px;
      color: #fc4848;
      cursor: pointer;
    }
    .iconRight i {
      color: #4c80fa;
    }
</style>