<template>
    <div class="homeContainer">
      <div class="homeBox">
        <p class="title">福利总览</p>
        <div class="overview">
          <el-row :gutter="20">
            <el-col :span="20">
              <el-row :gutter="20">
                <el-col :span="12">
                  <div class="viewBox" v-loading="loading1">
                    <div class="header">
                    <div class="left">
                      <span>企业积分余额：{{enterCredits.enterprisePointsBalance}}</span>
                      <span class="amount" @click="integralDetail">详情</span>
                    </div>
                    <div class="right">
                      <span class="stage" v-for="(tag,index) in stageList" :key="tag" @click="tagMethod(index)" :class="index===activeIndex?'tagActive':''">{{tag}}</span>
                    </div>
                  </div>
                  <div class="content">
                    <el-row>
                      <el-col :span="8">
                      <div class="top">
                        <span class="number">{{enterCredits.chongzhiPointsSum}}</span> 
                        <span class="trend">
                          <span v-if="!Number.isNaN(enterCredits.chongzhiAnnulus) && Number(enterCredits.chongzhiAnnulus) < 0" style="color: #00A870">
                            <i class="el-icon-bottom"></i> {{Math.abs(enterCredits.chongzhiAnnulus)}}
                          </span>
                          <span v-else-if="!Number.isNaN(enterCredits.chongzhiAnnulus) && Number(enterCredits.chongzhiAnnulus) > 0" style="color: #FC4848">
                            <i class="el-icon-top"></i> {{Math.abs(enterCredits.chongzhiAnnulus)}}
                          </span>
                          <span v-else style="color: #4C80FA">—</span>
                        </span>
                      </div>
                      <div class="bottom">企业充值积分</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="top">
                        <span class="number">{{enterCredits.fafangPointsSum}}</span> 
                        <span class="trend">
                          <span v-if="!Number.isNaN(enterCredits.fafangAnnulus) && Number(enterCredits.fafangAnnulus) < 0" style="color: #00A870">
                            <i class="el-icon-bottom"></i> {{Math.abs(enterCredits.fafangAnnulus)}}
                          </span>
                          <span v-else-if="!Number.isNaN(enterCredits.fafangAnnulus) && Number(enterCredits.fafangAnnulus) > 0" style="color: #FC4848">
                            <i class="el-icon-top"></i> {{Math.abs(enterCredits.fafangAnnulus)}}
                          </span>
                          <span v-else style="color: #4C80FA">—</span>
                        </span>
                      </div>
                      <div class="bottom">企业发放积分</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="top">
                        <span class="number">{{enterCredits.consumePointsSum}}</span> 
                        <span class="trend">
                          <span v-if="!Number.isNaN(enterCredits.consumeAnnulus) && Number(enterCredits.consumeAnnulus) < 0" style="color: #00A870">
                            <i class="el-icon-bottom"></i> {{Math.abs(enterCredits.consumeAnnulus)}}
                          </span>
                          <span v-else-if="!Number.isNaN(enterCredits.consumeAnnulus) && Number(enterCredits.consumeAnnulus) > 0" style="color: #FC4848">
                            <i class="el-icon-top"></i> {{Math.abs(enterCredits.consumeAnnulus)}}
                          </span>
                          <span v-else style="color: #4C80FA">—</span>
                        </span>
                      </div>
                      <div class="bottom">员工消费积分</div>
                    </el-col>
                    </el-row>
                  </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="viewBox" v-loading="loading2">
                    <div class="header">
                    <div class="left">
                      <span>节日礼包</span>
                      <span class="amount" @click="festivalDetail">详情</span>
                    </div>
                    <div class="right">
                      <el-select v-model="festivalType" placeholder="请选择" @change="festivalChange" class="selectBox">
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="content">
                    <el-row>
                      <el-col :span="8" class="imgContent">
                        <div>
                          <img :src="img1" alt="">
                        </div>
                        <div class="numberBox">
                          <span class="number">{{festiveGift.canGrantNum}}</span> <br>
                          可发放礼包
                        </div>
                      </el-col>
                      <el-col :span="8" class="imgContent">
                        <div>
                          <img :src="img2" alt="">
                        </div>
                        <div class="numberBox">
                          <span class="number">{{festiveGift.grantedNum}}</span> <br>
                          已发放礼包
                        </div>
                      </el-col>
                      <el-col :span="8" class="imgContent">
                        <div>
                          <img :src="img3" alt="">
                        </div>
                        <div class="numberBox">
                          <span class="number">{{festiveGift.notReceivedNum}}</span> <br>
                          未领取礼包
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="bottomBox">
                <el-col :span="12">
                  <div class="viewBox" v-loading="loading3">
                    <div class="header">
                    <div class="left">
                      <span>生日礼包</span>
                      <span class="amount" @click="insuranceDetail">详情</span>
                    </div>
                  </div>
                  <div class="content">
                    <el-row>
                      <el-col :span="8" class="imgContent">
                        <div>
                          <img :src="img1" alt="">
                        </div>
                        <div class="numberBox">
                          <span class="number">{{busiInsurance.serviceNum}}</span> <br>
                          可发放礼包
                        </div>
                      </el-col>
                      <el-col :span="8" class="imgContent">
                        <div>
                          <img :src="img2" alt="">
                        </div>
                        <div class="numberBox">
                          <span class="number">{{busiInsurance.grantedNum}}</span> <br>
                          已发放礼包
                        </div>
                      </el-col>
                      <el-col :span="8" class="imgContent">
                        <div>
                          <img :src="img3" alt="">
                        </div>
                        <div class="numberBox">
                          <span class="number">{{busiInsurance.notReceivedNum}}</span> <br>
                          未领取
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="viewBox" v-loading="loading4">
                    <div class="header">
                    <div class="left">
                      <span>年度体检</span>
                      <span class="amount" @click="examDetail">详情</span>
                    </div>
                    <div class="right">
                      <span class="stage">体检有效期：{{annualExam.ValidDate}}</span>
                    </div>
                  </div>
                  <div class="content">
                    <el-row>
                      <el-col :span="8" class="imgContent">
                        <div>
                          <img :src="img7" alt="">
                        </div>
                        <div class="numberBox">
                          <span class="number">{{annualExam.Total}}</span> <br>
                          总计
                        </div>
                      </el-col>
                      <el-col :span="8" class="imgContent">
                        <div>
                          <img :src="img8" alt="">
                        </div>
                        <div class="numberBox">
                          <span class="number">{{annualExam.IssuedQuantity}}</span> <br>
                          已发放
                        </div>
                      </el-col>
                      <el-col :span="8" class="imgContent">
                        <div>
                          <img :src="img9" alt="">
                        </div>
                        <div class="numberBox">
                          <span class="number">{{annualExam.MedicalExamed}}</span> <br>
                          已体检
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  </div>
                </el-col>
              </el-row>
            </el-col>
    
            <el-col :span="4">
              <div class="iconBox">
                <div class="iconImg">
                  <div @click="czjf">
                    <img :src="img10" alt=""> <br>
                    <span>充值积分</span>
                  </div>
                  <div @click="ffjf">
                    <img :src="img11" alt=""> <br>
                    <span>发放积分</span>
                  </div>
                </div>
                <div class="iconImg">
                  <div  @click="festivalDetail">
                    <img :src="img12" alt=""> <br>
                    <span>发放节日礼包</span>
                  </div>
                  <div @click="sbtb">
                    <img :src="img13" alt=""> <br>
                    <span>商保投保</span>
                  </div>
                </div>
                <div class="iconImg">
                  <div @click="insuranceDetail">
                    <img :src="img14" alt=""> <br>
                    <span>发放生日礼包</span>
                  </div>
                  <div @click="examDetail">
                    <img :src="img15" alt=""> <br>
                    <span>年度体检</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
    
        <p class="title">进行中的福利活动</p>
        <div class="proceeBox" v-loading="loading5">
          <div class="noDataBox" v-if="!welfaring.length">
            暂无数据
          </div>
          <el-row :gutter="20" v-else>
            <el-col :span="12" v-for="(item, index) in welfaring" :key="index">
              <div class="processLeft">
                <div :class="['left', GetwelfareActivityClass(item)]">
                  <p>{{item.festivalType}}</p>
                  <p class="one">线上节日福利</p>
                </div>
                <div class="right">
                  <div style="overflow: hidden;">
                    <div class="box1 box">
                      <span>可发放礼包</span> <br>
                      <span>{{Number(item.serviceNum) - Number(item.grantedNum)}}</span>
                    </div>
                    <div class="box2 box">
                      <span>已发放礼包</span> <br>
                      <span>{{item.grantedNum}}</span>
                    </div>
                    <div class="box3 box" v-if="item.festivalType != '生日福利'">
                      福利领取截止日：{{item.exchangeDeadline | timeFilter}}
                    </div>
                  </div>
                  <div class="btnBox" @click="giftDetail(item)">
                    <img :src="img16" alt="">
                    发放礼包
                  </div>
                  <div class="btnBox" @click="releaseRecord(item)">
                    <img :src="img20" alt="">
                    发放记录
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
    
        <p class="title echartTitle">统计图表
          <img :src="img18" alt="">
          <span @click="moreSetting">更多设置</span>
        </p>
        <div class="echartBox" v-loading="loading6">
          <div class="noDataBox" v-show="!settingArr.length" style="height: 300px; line-height: 300px;">暂无数据</div>
          <el-row :gutter="20" v-show="settingArr.length">
            <el-col :span="12" v-show="show1">
              <div class="bottom">
                <div id="one"></div>
              </div>
            </el-col>
            <el-col :span="12" v-show="show2">
              <div class="bottom">
                <div id="two"></div>
              </div>
            </el-col>
            <el-col :span="12" v-show="show3">
              <div class="bottom">
                <div id="three"></div>
              </div>
            </el-col>
            <el-col :span="12" v-show="show4">
              <div class="bottom">
                <div id="four"></div>
              </div>
            </el-col>
            <el-col :span="12" v-show="show5">
              <div class="bottom">
                <div id="five"></div>
              </div>
            </el-col>
            <el-col :span="12" v-show="show6">
              <div class="bottom">
                <div id="six"></div>
              </div>
            </el-col>
            <el-col :span="12" v-show="show7">
              <div class="bottom">
                <div id="seven"></div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- <div class="echartSetting" v-show="!homeShow" style="height: 100%">
        <el-row :gutter="20" style="height: 100%">
          <el-col :span="12" style="height: 100%">
            <div class="settingBox">
              <p class="header">设置常用统计图表 
                <span>（最少设置2个）</span>
              </p>
              <el-row :gutter="20">
                <el-col :span="8" v-for="(item, index) in settingArr" :key="index">
                  <div class="iconSetting">
                    <img :src="img19" alt="">
                    <i class="el-icon-remove" @click="removeMeth(item,index)"></i>
                    <span>{{item.optionName}}</span>
                  </div>
                </el-col>
              </el-row>
              <el-button class="one-button" @click="save(1)">保存</el-button>
              <el-button class="two-button" @click="save(0)">取消</el-button>
            </div>
          </el-col>
          <el-col :span="12" style="height: 100%">
            <div class="settingBox">
              <p class="header">全部图表预览</p>
              <el-row :gutter="20">
                <el-col :span="8" v-for="(item, index) in noSettingArr" :key="index">
                  <div class="iconSetting iconRight">
                    <img :src="img19" alt="">
                    <i class="el-icon-circle-plus" @click="addMethod(item,index)"></i>
                    <span>{{item.optionName}}</span>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div> -->
    </div>
  </template>
  
  <script>
  import {
    HrSummary, GetContractDatasSummary, PointFlowSummary, GiftSummary, GetOrderInsuranceSummary, GetContractBirthdaySummary, GrantData, ChargeData, ConsumeData, OrderData, ProportionData, WelfareConfigSetData, WelfareConfigSet
  } from "@/api/index";
    export default {
      filters:{
        timeFilter(val) {
          if(val) {
            return val.split('T')[0]
          }
        }
      },
      data() {
        return {
          loading1: true,
          loading2: true,
          loading3: true,
          loading4: true,
          loading5: true,
          loading6: true,
          settingArr: [],
          noSettingArr: [],
          giftUrl: 'https://festival-gifts-hr.test-01.54test.cn',
          qyjfCode: '041502',
          flCode: '041404',
          sbCode: '042101',
          tjCode: '041101',
          czCode: '041507',
          ffCode: '041506',
          tbCode: '041303',
          stopCode: '041301',
          // noSettingArr: [
          //   {isSetting: true, name: '积分发放金额'},
          //   {isSetting: true, name: '积分充值金额'},
          //   {isSetting: true, name: '员工消费力度统计'},
          //   {isSetting: false, name: '员工消费金额'},
          //   {isSetting: false, name: '员工消费订单数'},
          //   {isSetting: false, name: '员工消费类型金额占比'},
          //   {isSetting: false, name: '员工消费类型数量占比'},
          // ],
          show1: false,
          show2: false,
          show3: false,
          show4: false,
          show5: false,
          show6: false,
          show7: false,
          activeIndex: 0,
          stageList: ['全部','本周','本月','本年'],
          festivalType: '',
          options: [
            {label: '全部', value: ''},
            {label: '春节', value: '春节'},
            {label: '妇女节', value: '妇女节'},
            {label: '端午节', value: '端午节'},
            {label: '中秋节', value: '中秋节'},
            // {label: '生日福利', value: '生日福利'},
            {label: '公司周年庆', value: '公司周年庆'},
            {label: '其他', value: '其他'},
          ],
          homeShow: true,
          annualExam: {}, //年度体检
          welfaring: [], //进行中的福利
          enterCredits: {}, //企业积分余额
          festiveGift: {}, //节日礼包
          busiInsurance: {}, //商保
          grantAmountX: [], //积分发放金额x轴
          grantAmountY: [], //积分发放金额y轴
          chargeAmountX: [], //积分充值金额x轴
          chargeAmountY: [], //积分充值金额y轴
          consumeAmountX: [], //消费金额x轴
          consumeAmountY: [], //消费金额y轴
          orderAmountX: [], //员工消费订单数x轴
          orderAmountY: [], //员工消费订单数y轴
          selectMonArr: [],
          proportainAmount: [],
          amountTotal: 0,
          proportainNumber: [],
          numberTotal: 0,
          img1: require("@/assets/img/icon_kff.png"),
          img2: require("@/assets/img/icon_yff.png"),
          img3: require("@/assets/img/icon_wlq.png"),
          img4: require("@/assets/img/icon_cbrs.png"),
          img5: require("@/assets/img/icon_blrs.png"),
          img6: require("@/assets/img/icon_tj.png"),
          img7: require("@/assets/img/icon_zj.png"),
          img8: require("@/assets/img/icon_fyy.png"),
          img9: require("@/assets/img/icon_ytj.png"),
          img10: require("@/assets/img/czjf.png"),
          img11: require("@/assets/img/ffjf.png"),
          img12: require("@/assets/img/fflb.png"),
          img13: require("@/assets/img/sbtb.png"),
          img14: require("@/assets/img/sbtm.png"),
          img15: require("@/assets/img/ndtj.png"),
          img16: require("@/assets/img/gift.png"),
          img17: require("@/assets/img/icon_ndtj.png"),
          img18: require("@/assets/img/setting.png"),
          img19: require("@/assets/img/iconSetting.png"),
          img20: require("@/assets/img/jl.png"),
          img21: require("@/assets/img/fd.png"),
        }
      },
      mounted() {
        console.log(process.env.NODE_ENV)
        if(process.env.NODE_ENV === 'production') {
          this.giftUrl = 'https://festival-gifts-hr.fanyuanwang.cn'
          this.qyjfCode = '041501'
          this.flCode = '041403'
          this.sbCode = '041801'
          this.tjCode = '041101'
          this.czCode = '041506'
          this.ffCode = '041505'
          this.tbCode = '041302'
          this.stopCode = '041304'
        } else {
          this.giftUrl = 'https://festival-gifts-hr.test-01.54test.cn'
        }

        this.getMonth(12)
        this.WelfareConfigSetData()
        this.HrSummary()
        this.GetContractDatasSummary()
        this.PointFlowSummary(0)
        this.GiftSummary(this.festivalType)
        // this.GetOrderInsuranceSummary()
        this.GetContractBirthdaySummary()

        this.GrantData()
        this.ChargeData()
        this.ConsumeData()
        this.OrderData()
        this.ProportionData()
      },
      methods: {
        // 获取近一年时间
        getMonth(numMon) { //获取最近xx月份列表
          var dataArr = [];
          var data = new Date();
          data.setMonth(data.getMonth() + 1, 1) //获取到当前月份,设置月份
          for (var i = 0; i < numMon; i++) {
            data.setMonth(data.getMonth() - 1); //每次循环一次 月份值减1
            var m = data.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            dataArr.push(data.getFullYear() + "-" + (m));
          }
          this.selectMonArr = JSON.parse(JSON.stringify(dataArr))
          this.selectMonArr.reverse()
          console.log('近一年月份')
          console.log(this.selectMonArr)
          return [dataArr[dataArr.length - 1], dataArr[0]]
        },
        // 跳转
        integralDetail() {
          window.parent.postMessage(
            {
              type: "quickEnter",
              data: {
                configName: "企业账户",
                quickEnterCode: this.qyjfCode
              },
            },
            "*"
          );
        },
        releaseRecord(val) {
          // sessionStorage.setItem("festivalConfigurationDtoId", val.id);
          let end_time = new Date(val.exchangeDeadline).getTime() / 1000;
          window.location.href = `${this.giftUrl}/#/festival/adminList?festivalConfigurationDtoId=${val.id}&end_time=${end_time}&contractId=${val.id}&isUsing=${val.isUsing}`
        },
        giftDetail(val) {
          let type = 0;
          switch (val.festivalType) {
            case "春节":
              type = 0;
              break;
            case "妇女节":
              type = 1;
              break;
            case "端午节":
              type = 2;
              break;
            case "中秋节":
              type = 3;
              break;
            case "公司周年庆":
              type = 4;
              break;
            case "生日福利":
              type = 5;
              break;
            default:
              type = 6;
              break;
          }
          if (type === 5) {
            window.location.href = `${this.giftUrl}/#/birthday`
          } else if (type === 6) {
            window.location.href = `${this.giftUrl}/#/festival/admin_detail/${type}?guid=${val.guid}&name=${val.festivalType}`
            // this.$router.push(
            //   `https://festival-gifts-hr.test-01.54test.cn/#/festival/admin_detail/${type}?guid=${val.guid}&name=${val.festivalType}`
            // );
          } else {
            window.location.href = `${this.giftUrl}/#/festival/admin_detail/${type}?guid=${val.guid}`
            // this.$router.push(`https://festival-gifts-hr.test-01.54test.cn/#/festival/admin_detail/${type}?guid=${val.guid}`);
          }
          // window.parent.postMessage(
          //   {
          //     data: val,
          //     type: "GiftDetail",
          //   },
          //   "*"
          // );
        },
        festivalDetail() {
          window.parent.postMessage(
            {
              type: "quickEnter",
              data: {
                configName: "福利发放",
                quickEnterCode: this.flCode
              },
            },
            "*"
          );
        },
        insuranceDetail() {
          window.parent.postMessage(
            {
              type: "quickEnter",
              data: {
                configName: "福利发放",
                quickEnterCode: this.sbCode
              },
            },
            "*"
          );
        },
        examDetail() {
          window.parent.postMessage(
            {
              type: "quickEnter",
              data: {
                configName: "体检福袋",
                quickEnterCode: this.tjCode
              },
            },
            "*"
          );
        },
        czjf() {
          window.parent.postMessage(
            {
              type: "quickEnter",
              data: {
                configName: "积分充值",
                quickEnterCode: this.czCode
              },
            },
            "*"
          );
        },
        ffjf() {
          window.parent.postMessage(
            {
              type: "quickEnter",
              data: {
                configName: "积分发放",
                quickEnterCode: this.ffCode
              },
            },
            "*"
          );
        },
        sbtb() {
          window.parent.postMessage(
            {
              type: "quickEnter",
              data: {
                configName: "投保申报",
                quickEnterCode: this.tbCode
              },
            },
            "*"
          );
        },
        stop() {
          window.parent.postMessage(
            {
              type: "quickEnter",
              data: {
                configName: "可停保名单",
                quickEnterCode: this.stopCode
              },
            },
            "*"
          );
        },
        

        settingChange() {
          console.log('this.settingArr')
          console.log(this.settingArr)
          this.settingArr.forEach(item => {
            if(item.optionName === '积分发放金额') {
              this.show1 = true
            } else if (item.optionName === '积分充值金额') {
              this.show2 = true
            } else if (item.optionName === '员工消费力度统计') {
              this.show3 = true
            } else if (item.optionName === '员工消费金额') {
              this.show4 = true
            } else if (item.optionName === '员工消费订单数') {
              this.show5 = true
            } else if (item.optionName === '员工消费类型金额占比') {
              this.show6 = true
            } else if (item.optionName === '员工消费类型数量占比') {
              this.show7 = true
            }
          })
        },
        removeMeth(item,index) {
          if(this.settingArr.length > 2) {
            this.settingArr.splice(index, 1)
            this.noSettingArr.push(item)
          } else {
            this.$message({
              message: "常用统计图表最少设置2个",
              type: "warning",
              showClose: true,
            });
          }
        },
        addMethod(item,index) {
          this.noSettingArr.splice(index, 1)
          this.settingArr.push(item)
        },
        async HrSummary() {
          let data = await HrSummary();
          this.annualExam = data.data
          this.loading4 = false
        },
        async WelfareConfigSetData() {
          let data = await WelfareConfigSetData();
          console.log('获取福利已配置按钮')
          console.log(data)
          this.settingArr = data.data.已配置
          this.noSettingArr = data.data.未配置
          this.settingChange()
        },
        async WelfareConfigSet(val) {
          let data = await WelfareConfigSet(val);
          console.log('保存福利工作台配置')
          console.log(data)
        },
        async GetContractDatasSummary() {
          let data = await GetContractDatasSummary({PageIndex: 1, PageSize: 10});
          console.log('进行中的福利')
          console.log(data)
          this.welfaring = data.data
          this.loading5 = false
        },
        async PointFlowSummary(val) {
          let data = await PointFlowSummary({dateType: val});
          console.log('企业积分余额')
          console.log(data)
          this.enterCredits = data.data
          this.loading1 = false
        },
        async GiftSummary(val) {
          let data = await GiftSummary({festivalType: val});
          console.log('节日礼包')
          console.log(data)
          this.festiveGift = data.data
          this.loading2 = false
        },
        async GetOrderInsuranceSummary() {
          let data = await GetOrderInsuranceSummary();
          console.log('员工商保')
          console.log(data)
          this.busiInsurance = data.data
          this.loading3 = false
        },
        async GetContractBirthdaySummary() {
          let data = await GetContractBirthdaySummary();
          console.log('生日礼包')
          console.log(data)
          this.busiInsurance = data.data
          this.loading3 = false
        },
        async GrantData() {
          let data = await GrantData({monthRange: `${this.selectMonArr[0]},${this.selectMonArr[this.selectMonArr.length - 1]}`});
          console.log('积分发放金额')
          console.log(data)
          // let xAxis = []
          // let yAxis = []
          // data.data.analysisData.forEach(item => {
          //   xAxis.push(item.yearMonth)
          //   yAxis.push(item.value)
          // })
          // this.grantAmountX = xAxis.reverse()
          // this.grantAmountY = yAxis.reverse()
          // this.$nextTick(function() {
          //   this.initCharOne()
          // })

          this.loading6 = false
          this.grantAmountX = this.selectMonArr
          this.grantAmountY = []
          /* 匹配图表下标 (数据给的是无序月份,并且可能缺失月份) */
          this.selectMonArr.map(item => {
            let flag = true
            data.data.analysisData.map(resItem => {
              if (item == resItem.yearMonth) {
                this.grantAmountY.push(resItem.value)
                flag = false
              }
            })
            if (flag) {
              this.grantAmountY.push(0)
            }
          })
          this.initCharOne()
        },
        async ChargeData() {
          let data = await ChargeData({monthRange: `${this.selectMonArr[0]},${this.selectMonArr[this.selectMonArr.length - 1]}`});
          console.log('积分充值金额')
          console.log(data)
          this.loading6 = false
          // let xAxis = []
          // let yAxis = []
          // data.data.analysisData.forEach(item => {
          //   xAxis.push(item.yearMonth)
          //   yAxis.push(item.value)
          // })
          // this.chargeAmountX = xAxis.reverse()
          // this.chargeAmountY = yAxis.reverse()
          // this.$nextTick(function() {
          //   this.initCharTwo()
          // })

          this.chargeAmountX = this.selectMonArr
          this.chargeAmountY = []
          /* 匹配图表下标 (数据给的是无序月份,并且可能缺失月份) */
          this.selectMonArr.map(item => {
            let flag = true
            data.data.analysisData.map(resItem => {
              if (item == resItem.yearMonth) {
                this.chargeAmountY.push(resItem.value)
                flag = false
              }
            })
            if (flag) {
              this.chargeAmountY.push(0)
            }
          })
          this.initCharTwo()
        },
        async ConsumeData() {
          let data = await ConsumeData({monthRange: `${this.selectMonArr[0]},${this.selectMonArr[this.selectMonArr.length - 1]}`});
          console.log('消费力度统计(消费总金额/员工消费金额)')
          console.log(data)
          this.loading6 = false
          // let xAxis = []
          // let yAxis = []
          // data.data.analysisData.forEach(item => {
          //   xAxis.push(item.yearMonth)
          //   yAxis.push(item.value)
          // })
          // this.consumeAmountX = xAxis.reverse()
          // this.consumeAmountY = yAxis.reverse()
          // this.$nextTick(function() {
          //   this.initCharThree()
          //   this.initCharFour()
          // })

          this.consumeAmountX = this.selectMonArr
          this.consumeAmountY = []
          /* 匹配图表下标 (数据给的是无序月份,并且可能缺失月份) */
          this.selectMonArr.map(item => {
            let flag = true
            data.data.analysisData.map(resItem => {
              if (item == resItem.yearMonth) {
                this.consumeAmountY.push(resItem.value)
                flag = false
              }
            })
            if (flag) {
              this.consumeAmountY.push(0)
            }
          })
          this.initCharThree()
          this.initCharFour()
        },
        async OrderData() {
          let data = await OrderData({monthRange: `${this.selectMonArr[0]},${this.selectMonArr[this.selectMonArr.length - 1]}`});
          console.log('员工消费订单数')
          console.log(data)
          this.loading6 = false
          // let xAxis = []
          // let yAxis = []
          // data.data.analysisData.forEach(item => {
          //   xAxis.push(item.yearMonth)
          //   yAxis.push(item.value)
          // })
          // this.orderAmountX = xAxis.reverse()
          // this.orderAmountY = yAxis.reverse()
          // this.$nextTick(function() {
          //   this.initCharFive()
          // })

          this.orderAmountX = this.selectMonArr
          this.orderAmountY = []
          /* 匹配图表下标 (数据给的是无序月份,并且可能缺失月份) */
          this.selectMonArr.map(item => {
            let flag = true
            data.data.analysisData.map(resItem => {
              if (item == resItem.yearMonth) {
                this.orderAmountY.push(resItem.value)
                flag = false
              }
            })
            if (flag) {
              this.orderAmountY.push(0)
            }
          })
          this.initCharFive()
        },
        async ProportionData() {
          let data = await ProportionData({monthRange: `${this.selectMonArr[0]},${this.selectMonArr[this.selectMonArr.length - 1]}`});
          console.log('员工消费类型占比')
          console.log(data)
          this.loading6 = false
          this.proportainAmount = [
            {name: '实物', value: data.data.material ? data.data.material.orderPoint : 0},
            {name: '电子卡券', value: data.data.card ? data.data.card.orderPoint : 0},
            {name: '充值中心', value: data.data.directCharge ? data.data.directCharge.orderPoint : 0},
          ]
          let a = data.data.material ? Number(data.data.material.orderPoint) : 0
          let b = data.data.card ? Number(data.data.card.orderPoint) : 0
          let c = data.data.directCharge ? Number(data.data.directCharge.orderPoint) : 0

          this.amountTotal = a + b + c
          this.proportainNumber = [
            {name: '实物', value: data.data.material ? data.data.material.orderNum : 0},
            {name: '电子卡券', value: data.data.card ? data.data.card.orderNum : 0},
            {name: '充值中心', value: data.data.directCharge ? data.data.directCharge.orderNum : 0},
          ]

          let a1 = data.data.material ? Number(data.data.material.orderNum) : 0
          let b1 = data.data.card ? Number(data.data.card.orderNum) : 0
          let c1 = data.data.directCharge ? Number(data.data.directCharge.orderNum) : 0
          this.numberTotal = a1 + b1 + c1
          this.$nextTick(function() {
            this.initCharSix()
            this.initCharSeven()
          })
        },


        festivalChange() {
          this.GiftSummary(this.festivalType)
        },
        // 获取福利活动listItem Class名
        GetwelfareActivityClass(item) {
          switch (item.festivalType) {
            case '春节':
              return 'cj'
            case '妇女节':
              return 'fn'
            case '端午节':
              return 'dw'
            case '中秋节':
              return 'zq'
            case '公司周年庆':
              return 'zn'
            case '生日福利':
              return 'sr'
            case '自定义福利':
              return 'zdy'
            default:
              return ''
          }
        },
        save(e) {
          if(e === 1) {
            this.show1 = false
            this.show2 = false
            this.show3 = false
            this.show4 = false
            this.show5 = false
            this.show6 = false
            this.show7 = false
            this.settingChange()
            let ids = []
            this.settingArr.forEach(item => {
              ids.push(item.id)
            })
            this.WelfareConfigSet(ids)
          }
          this.homeShow = true
        },
        moreSetting() {
          this.$router.push("/MoreSetting");
          // this.WelfareConfigSetData()
          // this.homeShow = false
        },
        initCharOne() {
          // 基于准备好的dom，初始化echarts实例
          var myChart = this.$echarts.init(document.getElementById('one'));
          console.log('echart1')
          // 指定图表的配置项和数据
          var option = {
            grid:{
              right: 60,
              left: 70
            },
            title: {
              text: '积分发放金额',
              x: 30, 
              textStyle: {
                fontSize: 18,
                fontWeight: 'normal',
                color: '#303133'
              }
            },
            tooltip: {
              // position: 'top',
              trigger: 'axis',
              axisPointer: { // 坐标轴指示器，坐标轴触发有效
                type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                shadowStyle: {
                  color: '#E4E6EB',
                  opacity: 0.3
                }
              },
              borderColor: '#fff',
              textStyle: {
                color: '#303133',
                fontSize: 14
              },
              formatter: (params) => {
                console.log(params)
                return `<div>${params[0].name}</div>
                        <div>发放金额 <span style="margin-left: 3px">${params[0].data}</span> </div>
                `
              }
            },
            // legend: {
            //   data: ['销量']
            // },
            toolbox: { // ！！！
              show: true,
              x: '85%', 
              feature: {
                saveAsImage: {}  // 导出图片
              }
            },
            xAxis: {
              axisLine: {
                show:false,
              },
              axisTick: {
                show:false,
              },
              data: this.grantAmountX
            },
            yAxis: {
              splitLine:{
                show:true,
                lineStyle:{
                  type:'dashed'
                }
              },
              axisLine: {
                show: false
              }
            },
            series: [
              {
                name: '积分发放金额',
                type: 'bar',
                barWidth: 20,
                itemStyle: {
                  normal: {
                      barBorderRadius: [3, 3, 0, 0],
                      color: new this.$echarts.graphic.LinearGradient(
                          0, 0, 0, 1,
                          [
                              {offset: 0, color: '#ADC3F9'},
                              {offset: 1, color: '#4C79E9'}
                          ]
                      )
                  }
              },
                data: this.grantAmountY
              }
            ]
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
        initCharTwo() {
          // 基于准备好的dom，初始化echarts实例
          var myChart = this.$echarts.init(document.getElementById('two'));
          console.log('echart2')
          // 指定图表的配置项和数据
          var option = {
            grid:{
              right: 60,
              left: 70
            },
            title: {
              text: '积分充值金额',
              x: 30, 
              textStyle: {
                fontSize: 18,
                fontWeight: 'normal',
                color: '#303133'
              }
            },
            tooltip: {
              // position: 'top',
              trigger: 'axis',
              axisPointer: { // 坐标轴指示器，坐标轴触发有效
                type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                shadowStyle: {
                  color: '#E4E6EB',
                  opacity: 0.3
                }
              },
              borderColor: '#fff',
              textStyle: {
                color: '#303133',
                fontSize: 14
              },
              formatter: (params) => {
                return `<div>${params[0].name}</div>
                        <div>充值金额 <span style="margin-left: 3px">${params[0].data}</span> </div>
                `
                // console.log(params)
              }
            },
            // legend: {
            //   data: ['销量']
            // },
            toolbox: { 
              show: true,
              x: '85%', 
              feature: {
                saveAsImage: {}  // 导出图片
              }
            },
            xAxis: {
              axisLine: {
                show:false,
              },
              axisTick: {
                show:false,
              },
              data: this.chargeAmountX
            },
            yAxis: {
              splitLine:{
                show:true,
                lineStyle:{
                  type:'dashed'
                }
              },
              axisLine: {
                show: false
              }
            },
            series: [
              {
                name: '积分充值金额',
                type: 'bar',
                barWidth: 20,
                itemStyle: {
                  normal: {
                      barBorderRadius: [3, 3, 0, 0],
                      color: new this.$echarts.graphic.LinearGradient(
                          0, 0, 0, 1,
                          [
                              {offset: 0, color: '#97E9CE'},
                              {offset: 1, color: '#45C49C'}
                          ]
                      )
                  }
              },
                data: this.chargeAmountY
              }
            ]
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
        initCharThree() {
          // 基于准备好的dom，初始化echarts实例
          var myChart = this.$echarts.init(document.getElementById('three'));
          console.log('echart3')
          // 指定图表的配置项和数据
          var option = {
            title: {
              text: '员工消费力度统计',
              x: 30, 
              textStyle: {
                fontSize: 18,
                fontWeight: 'normal',
                color: '#303133'
              }
            },
            tooltip: {
              trigger: 'axis',
              position: 'top',
              borderColor: '#fff',
              textStyle: {
                color: '#303133',
                fontSize: 14
              },
              formatter: (params) => {
                console.log(params)
                return `
                  <div>${params[0].name}</div>
                  <div>
                    <span style="display:inline-block;width:6px;height:6px;border-radius:50%;border:2px solid #789af0"></span>
                    <span style="margin: 0 3px">发放总金额</span>
                    <span>${params[0].data}</span>
                  </div>
                  <div>
                    <span style="display:inline-block;width:6px;height:6px;border-radius:50%;border:2px solid #f9b76b"></span>
                    <span style="margin: 0 3px">消费总金额</span>
                    <span>${params[1].data}</span>
                  </div>
                `
              }
            },
            legend: {
              data: ['发放', '消费']
            },
            grid: {
              right: 60,
              left: 70
            },
            toolbox: {
              x: '92.5%',
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              // boundaryGap: false,
              axisLine: {
                show:false,
              },
              axisTick: {
                show:false,
              },
              data: this.consumeAmountX
            },
            yAxis: {
              type: 'value',
              splitLine:{
                show:true,
                lineStyle:{
                  type:'dashed'
                }
              },
              axisLine: {
                show: false
              }
            },
            series: [
              {
                name: '发放',
                type: 'line',
                smooth: true,
                showSymbol: false,
                itemStyle: {
                  normal: {
                    color: "#789af0",
                    lineStyle: {
                      // width: 0,
                      color: "#789af0"
                    }
                  }
                },
                data: this.grantAmountY
              },
              {
                name: '消费',
                type: 'line',
                smooth: true,
                showSymbol: false,
                itemStyle: {
                  normal: {
                    color: "#f9b76b",
                    lineStyle: {
                      // width: 0,
                      color: "#f9b76b"
                    }
                  }
                },
                data: this.consumeAmountY
              },
            ]
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
        initCharFour() {
          // 基于准备好的dom，初始化echarts实例
          var myChart = this.$echarts.init(document.getElementById('four'));
          console.log('echart4')
          // 指定图表的配置项和数据
          var option = {
            grid:{
              right: 60,
              left: 70
            },
            title: {
              text: '员工消费金额',
              x: 30, 
              textStyle: {
                fontSize: 18,
                fontWeight: 'normal',
                color: '#303133'
              }
            },
            tooltip: {
              // position: 'top',
              trigger: 'axis',
              axisPointer: { // 坐标轴指示器，坐标轴触发有效
                type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                shadowStyle: {
                  color: '#E4E6EB',
                  opacity: 0.3
                }
              },
              borderColor: '#fff',
              textStyle: {
                color: '#303133',
                fontSize: 14
              },
              formatter: (params) => {
                console.log(params)
                return `<div>${params[0].name}</div>
                        <div>员工消费金额 <span style="margin-left: 3px">${params[0].data}</span> </div>
                `
              }
            },
            // legend: {
            //   data: ['销量']
            // },
            toolbox: { // ！！！
              show: true,
              x: '85%', 
              feature: {
                saveAsImage: {}  // 导出图片
              }
            },
            xAxis: {
              axisLine: {
                show:false,
              },
              axisTick: {
                show:false,
              },
              data: this.consumeAmountX
            },
            yAxis: {
              splitLine:{
                show:true,
                lineStyle:{
                  type:'dashed'
                }
              },
              axisLine: {
                show: false
              }
            },
            series: [
              {
                name: '员工消费金额',
                type: 'bar',
                barWidth: 20,
                itemStyle: {
                  normal: {
                      barBorderRadius: [3, 3, 0, 0],
                      color: new this.$echarts.graphic.LinearGradient(
                          0, 0, 0, 1,
                          [
                              {offset: 0, color: '#f9c1c6'},
                              {offset: 1, color: '#f3747d'}
                          ]
                      )
                  }
              },
                data: this.consumeAmountY
              }
            ]
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
        initCharFive() {
          // 基于准备好的dom，初始化echarts实例
          var myChart = this.$echarts.init(document.getElementById('five'));
          console.log('echart5')
          // 指定图表的配置项和数据
          var option = {
            grid:{
              right: 60,
              left: 70
            },
            title: {
              text: '员工消费订单数',
              x: 30, 
              textStyle: {
                fontSize: 18,
                fontWeight: 'normal',
                color: '#303133'
              }
            },
            tooltip: {
              // position: 'top',
              trigger: 'axis',
              axisPointer: { // 坐标轴指示器，坐标轴触发有效
                type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                shadowStyle: {
                  color: '#E4E6EB',
                  opacity: 0.3
                }
              },
              borderColor: '#fff',
              textStyle: {
                color: '#303133',
                fontSize: 14
              },
              formatter: (params) => {
                console.log(params)
                return `<div>${params[0].name}</div>
                        <div>员工消费订单数 <span style="margin-left: 3px">${params[0].data}</span> </div>
                `
              }
            },
            toolbox: {
              show: true,
              x: '85%', 
              feature: {
                saveAsImage: {}  // 导出图片
              }
            },
            xAxis: {
              axisLine: {
                show:false,
              },
              axisTick: {
                show:false,
              },
              data: this.orderAmountX
            },
            yAxis: {
              splitLine:{
                show:true,
                lineStyle:{
                  type:'dashed'
                }
              },
              axisLine: {
                show: false
              }
            },
            series: [
              {
                name: '员工消费订单数',
                type: 'bar',
                barWidth: 20,
                itemStyle: {
                  normal: {
                      barBorderRadius: [3, 3, 0, 0],
                      color: new this.$echarts.graphic.LinearGradient(
                          0, 0, 0, 1,
                          [
                              {offset: 0, color: '#b6adf4'},
                              {offset: 1, color: '#8c7eee'}
                          ]
                      )
                  }
              },
                data: this.orderAmountY
              }
            ]
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
        initCharSix() {
          // 基于准备好的dom，初始化echarts实例
          var myChart = this.$echarts.init(document.getElementById('six'));
          console.log('echart6')
          // 指定图表的配置项和数据
          var option = {
            backgroundColor: '#fff',
            tooltip: {
              trigger: 'item',
              borderColor: '#fff',
              textStyle: {
                color: '#303133',
                fontSize: 14
              },
              // formatter: `{b} {d}%<br/> 消费金额 {c}`
              formatter: e => {
                return `${e.name} ${e.percent}% </br>消费金额 ${this.amountTotal}`
              }
            },
            title: {
              text: '员工消费类型金额占比',
              x: 30, 
              textStyle: {
                fontSize: 18,
                fontWeight: 'normal',
                color: '#303133'
              }
            },
            toolbox: {
              show: true,
              x: '85%', 
              feature: {
                saveAsImage: {}  // 导出图片
              }
            },
            grid: {
              x: 0,
              y: 0,
              x2: 0,
              y2: 90,
            },
            legend: {
              orient: 'vertical',
              top: 100,
              right: '20%',
              data: [{
                  icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAEXRFWHRTb2Z0d2FyZQBTbmlwYXN0ZV0Xzt0AAAMhSURBVEiJtZXNbxtVFMV/72PGju0GJ6Vx46pJ3RIHmYpApUApXSCkwgokxD/Ahj+PVkIIsUDqolISmQ8RQkPipGpc1IQ6aZ042PHMvJnHYpKQ1hknRXBWo/dxz71nzr1PWGst/yN0v822B/VGyMq6od21bLbiXM4NCrJpQbmoGR9RZFIvSdDx4Ne1gJklw/qziO12RGBgz48JBlyBoyGfNRSHJTde17xZcsi4vbHEixI92Y747mefH1cNT3cjTHiCBArOnpFMT2g+ftulkJfJBOvPIm7PelRXDJ5vOe3PEUDKFbxb1nx2PUVx+B+Sw6+NZsSdWY/ZpYDuSwQHsEDXt8z8HvDNDz5breh5gm4A1ZphrhYQnCBJPwQh3Fv0mVkydIMjBGtPQuZqAV7w74MfwAtgbjlgrRFnqrs+LD8OebCRnLpW4GqBkiAEmBC8wBJGx59f3QhZfhxyaUShm39FrPYJ7mqYnnC4NeVyqaAYcOH+o5Bvf/KYf2gSJX2wEdKciJDbnYh6I5lgqqT54sM0lbG4oYSAq+OKLz8aoHIxuU/rmyGtjkV6AezsJdQKvDfpkEmLnvVcWvB+xUm81+laAmORJoQ9L/Eco8MSR/Wuq/0GS8JOx+IZkFJwbIAD7HYs0TEFRhY6XnK3pB1QEmTKgcFMrwQHmF8zhzPoKLqeZaFuEu+9kpWkHYHMpQXnh5JLra4Y7i0G/NmM8A1YG4+Uuws+1Vpy45wblGRSoIdyktdGNfcfHe+kzZ2Ir6seC/WQfFbgKGjsxNbebidLdGVUkc9JdDzXFSN5SWP7eDdttSxbrdO3eSEvKV9Q5FICqWTMdu2yRiUrdWpoCdcua66cV0i5P4vyWcHNisMbY30fuFOhMqa5WXHIZ2PjSAApoFRQfDLtUi728ewJmLyg+PQdl1JBIfeN+dyDY0KorYd8NdNl8Y8wcZi9CCWhclHx+Y005aJCH8mx58mMIni6a7n7m8/3v/h9nQKxvLfecvngqsvZQXGYeSLBAayFZtsy/9AwtxzQ3I2ob8Uljb8qGTojuT7pMFXSDGUFIqFXEwn+K/wND1dWtKxGdqoAAAAASUVORK5CYII=',
                  name: '实物',
                }, {
                  icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAEXRFWHRTb2Z0d2FyZQBTbmlwYXN0ZV0Xzt0AAAIhSURBVEiJvZa/b9NAFMe/jl18mQiZmkyELFjOkCowdOjQSmRPhkRiQmz8R0yICakdiMSCFKRmYGCAqh1iOUsIU9vJNVPO5dJjiOPYsX22qcl39N17nzu/H/ckzjnHFlXYJgwAlDSbuGWDGSaYYYLPKe4urwEAheoupCKBomtQdA1SuZToSxL9Um7ZcL6MwH6cpzv9sz2oLw6F4FggG5ugxwOA0lQwT4SA9DtQGlp6IPt+DnoyyAba5PY6UJ7vhb6HkiYPGADQkwHY2BQDuWWDfvp8b5gHPR6AW3Y80BmeZo+ZkEiXPqOA3LLBzi7yg7liZxeBW3p1yIzw/96Uoj/FzsE+5GoFKBIspjPcDkdY/Pwlhhomdg72AfhuGBXgTRh59RJyvQYUCQBArtdQfPMa8pPHYqDPtwe8s26ERqsTRulB+1Boy315sY6h/VtoJNdr8WvVitB21QoDwG3JA0qlh8KNi+ksfu3ySgyp7IaBhfIjodGfr99i126HI6Gt5CZZABjXbFdixgT0/YdlCcyXSbCYzjB/+y6xLPy+vTpUdA1OQltjxgTMmAj3RAL1NXAdw3IJSquZ2VkirNUMvI+BLFXbRwBR86MRdenTpwBQKpdA+t38eP1u6PUP1aHS0EB6nfvDetGvfsKI8RGgTkaSCtLvZhsxVuKWDWd4mvrZUlpNqO2jfxuiNsHMMMHG7ph45Y6JFXdMbOQ0Jv4Pbb15/wXcG91JH9s6mQAAAABJRU5ErkJggg==',
                  name: '电子卡券',
                },
                {
                  icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAgCAYAAADqgqNBAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAEXRFWHRTb2Z0d2FyZQBTbmlwYXN0ZV0Xzt0AAAQoSURBVEiJxZfbbxtFFMZ/M7Pe9bVx6tyrUqA0SH1ArZQqQpUQFCQeqMQj7/xhvPMIUoWEoBJIIFUVFaC2KFGJmlKaxLn7knjXO3N4WON0k3idlFacB689Z+b75uyc+c6xEhHhfzLvRLMkRuw+ErfBdUAs4iIAlPZBGdB5lFdCmQKok8FmzxKHuBCJ6rjmIrbxO7b9GImbSOdZQp6fQXkVTOl1zJl30JVZlD+B0gEonQmvBr52sUi0QbR6C7vxIy5aB3GAgEjyTCBAqd5To/1xzNh7+FOfoPyx5K2chlziFrbxK9FfXyLhGhI3QWxmFAeIBuVVUMEk/vnPMGeuoLzyycgl2sJu3yFa+Qq396RHetqcVKAMuvga/vSnmNF5lH/2yKzUmUu0Qbx1h3jtG1x76ZSEKSSQGNdeort6C1EKb3QelRtNzTrICBdiGw+I17/Hthb/A3HabGuRuH4b23gALkz5+pG7zjPi7bvY5sJLI+5voPEAFUygC+fQxTf640nkYol37uGaD0G6L50c6eKaD4l37qUSVye+bVzjPm7/6RAUlQiIDlAmnwiKDnqiojJXuv2nuMZ9pLvdH/MAbHMBiTaHBqDzk5jqNbzau+jCDOgirr1EvH6beOeXoRgSbWKbC3i1sQNy11rERVtDiGfwJj8mN/4heJW+gunKZXL5GVQwTnftWyRaHxx9tIVrLULteoIJ4MI6xO1s8srbmJErqGCip99JuiiTR+enMNU5zJnLmRjYDtLdPcAEkHgXOXQNjpAXLqAL5zP85zL9AOI6qTPX/+4IiTMXqlxloEwCKFNAecVMDFyUSHWKXOWGViBseEQkUiZd6JXZjB0mt+N5cpUbSQ0eu+nO37jOymB/uIHr1IdwF1HB2GHyKkrnMxfa5h9Jpg7y797H7v6WiYHyULrQ/+kBmPIs9pAAHDa394Tuyte4aBPv7Dw6PwW6gGs/Jt78gXjzZ1y4ms3t1zDl2TS5rsyi/Rqu/WjwSomxe8u47g7x5k+9e24Qu4dEW0i8O7Tma7+GrhwmDybR5UuovSUkHCwSuBAJ60iYfbbHmQrG0eVL6GDyYDPJZ4CpXk1eSUbb88KmDKY8i6lePZrtAKZ0EVOdGyoUL2K6cD5RwNLF9Hj/myliRufwJm4kjd9LMuWP4Y3fwIzOgUmLUEpZdDBJbuwD/OmbKFNiWJkcQosyJfzpm+TG30+ddX/G0e5VkF5Gh8tf9ArOCzSQXongwud4teuoXJXjAjm+bxeH2Bau/Sfd+nfY7btId+dktLkqZvQauYmP0KWLKFMeKN2D/zQg4CJcZxW3v4xrL+Faj3Cdld7dThoH5ddQpojOT6PLb6FLbyYVMD8F2j824hOQP2cuRKItXFhP6rGLENtKAEwZtI/KjaCDiaQ/H1InTkf+imxIHX219g8BW8x3YQCFzAAAAABJRU5ErkJggg==',
                  name: '充值中心',
                }
              ],
              selectedMode: false,
              itemWidth: 14, // 设置宽度
              itemHeight: 14, // 设置高度
            },
            series: [{
              name: '消费情况',
              type: 'pie',
              radius: ['50%', '70%'],
              center: ['40%', '50%'],
              avoidLabelOverlap: false,
              label: {
                show: true,
                formatter: `消费总额\n${this.amountTotal}`,
                position: 'center',
                lineHeight: 27,
                color: '#15171A',
                fontSize: 18
              },
              color: ['#7795F6', '#F8868E', '#F3C445'],
              data: this.proportainAmount
            }]
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
        initCharSeven() {
          // 基于准备好的dom，初始化echarts实例
          var myChart = this.$echarts.init(document.getElementById('seven'));
          console.log('echart7')
          // 指定图表的配置项和数据
          var option = {
            backgroundColor: '#fff',
            tooltip: {
              trigger: 'item',
              borderColor: '#fff',
              textStyle: {
                color: '#303133',
                fontSize: 14
              },
              // formatter: `{b} {d}%<br/> 消费金额 {c}`
              formatter: e => {
                return `${e.name} ${e.percent}% </br> 订单数 ${this.numberTotal}`
              }
            },
            title: {
              text: '员工消费类型数量占比',
              x: 30, 
              textStyle: {
                fontSize: 18,
                fontWeight: 'normal',
                color: '#303133'
              }
            },
            grid: {
              x: 0,
              y: 0,
              x2: 0,
              y2: 90,
            },
            toolbox: {
              show: true,
              x: '85%', 
              feature: {
                saveAsImage: {}  // 导出图片
              }
            },
            legend: {
              orient: 'vertical',
              top: 100,
              right: '20%',
              data: [{
                  icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAEXRFWHRTb2Z0d2FyZQBTbmlwYXN0ZV0Xzt0AAAMhSURBVEiJtZXNbxtVFMV/72PGju0GJ6Vx46pJ3RIHmYpApUApXSCkwgokxD/Ahj+PVkIIsUDqolISmQ8RQkPipGpc1IQ6aZ042PHMvJnHYpKQ1hknRXBWo/dxz71nzr1PWGst/yN0v822B/VGyMq6od21bLbiXM4NCrJpQbmoGR9RZFIvSdDx4Ne1gJklw/qziO12RGBgz48JBlyBoyGfNRSHJTde17xZcsi4vbHEixI92Y747mefH1cNT3cjTHiCBArOnpFMT2g+ftulkJfJBOvPIm7PelRXDJ5vOe3PEUDKFbxb1nx2PUVx+B+Sw6+NZsSdWY/ZpYDuSwQHsEDXt8z8HvDNDz5breh5gm4A1ZphrhYQnCBJPwQh3Fv0mVkydIMjBGtPQuZqAV7w74MfwAtgbjlgrRFnqrs+LD8OebCRnLpW4GqBkiAEmBC8wBJGx59f3QhZfhxyaUShm39FrPYJ7mqYnnC4NeVyqaAYcOH+o5Bvf/KYf2gSJX2wEdKciJDbnYh6I5lgqqT54sM0lbG4oYSAq+OKLz8aoHIxuU/rmyGtjkV6AezsJdQKvDfpkEmLnvVcWvB+xUm81+laAmORJoQ9L/Eco8MSR/Wuq/0GS8JOx+IZkFJwbIAD7HYs0TEFRhY6XnK3pB1QEmTKgcFMrwQHmF8zhzPoKLqeZaFuEu+9kpWkHYHMpQXnh5JLra4Y7i0G/NmM8A1YG4+Uuws+1Vpy45wblGRSoIdyktdGNfcfHe+kzZ2Ir6seC/WQfFbgKGjsxNbebidLdGVUkc9JdDzXFSN5SWP7eDdttSxbrdO3eSEvKV9Q5FICqWTMdu2yRiUrdWpoCdcua66cV0i5P4vyWcHNisMbY30fuFOhMqa5WXHIZ2PjSAApoFRQfDLtUi728ewJmLyg+PQdl1JBIfeN+dyDY0KorYd8NdNl8Y8wcZi9CCWhclHx+Y005aJCH8mx58mMIni6a7n7m8/3v/h9nQKxvLfecvngqsvZQXGYeSLBAayFZtsy/9AwtxzQ3I2ob8Uljb8qGTojuT7pMFXSDGUFIqFXEwn+K/wND1dWtKxGdqoAAAAASUVORK5CYII=',
                  name: '实物',
                }, {
                  icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAEXRFWHRTb2Z0d2FyZQBTbmlwYXN0ZV0Xzt0AAAIhSURBVEiJvZa/b9NAFMe/jl18mQiZmkyELFjOkCowdOjQSmRPhkRiQmz8R0yICakdiMSCFKRmYGCAqh1iOUsIU9vJNVPO5dJjiOPYsX22qcl39N17nzu/H/ckzjnHFlXYJgwAlDSbuGWDGSaYYYLPKe4urwEAheoupCKBomtQdA1SuZToSxL9Um7ZcL6MwH6cpzv9sz2oLw6F4FggG5ugxwOA0lQwT4SA9DtQGlp6IPt+DnoyyAba5PY6UJ7vhb6HkiYPGADQkwHY2BQDuWWDfvp8b5gHPR6AW3Y80BmeZo+ZkEiXPqOA3LLBzi7yg7liZxeBW3p1yIzw/96Uoj/FzsE+5GoFKBIspjPcDkdY/Pwlhhomdg72AfhuGBXgTRh59RJyvQYUCQBArtdQfPMa8pPHYqDPtwe8s26ERqsTRulB+1Boy315sY6h/VtoJNdr8WvVitB21QoDwG3JA0qlh8KNi+ksfu3ySgyp7IaBhfIjodGfr99i126HI6Gt5CZZABjXbFdixgT0/YdlCcyXSbCYzjB/+y6xLPy+vTpUdA1OQltjxgTMmAj3RAL1NXAdw3IJSquZ2VkirNUMvI+BLFXbRwBR86MRdenTpwBQKpdA+t38eP1u6PUP1aHS0EB6nfvDetGvfsKI8RGgTkaSCtLvZhsxVuKWDWd4mvrZUlpNqO2jfxuiNsHMMMHG7ph45Y6JFXdMbOQ0Jv4Pbb15/wXcG91JH9s6mQAAAABJRU5ErkJggg==',
                  name: '电子卡券',
                },
                {
                  icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAgCAYAAADqgqNBAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAEXRFWHRTb2Z0d2FyZQBTbmlwYXN0ZV0Xzt0AAAQoSURBVEiJxZfbbxtFFMZ/M7Pe9bVx6tyrUqA0SH1ArZQqQpUQFCQeqMQj7/xhvPMIUoWEoBJIIFUVFaC2KFGJmlKaxLn7knjXO3N4WON0k3idlFacB689Z+b75uyc+c6xEhHhfzLvRLMkRuw+ErfBdUAs4iIAlPZBGdB5lFdCmQKok8FmzxKHuBCJ6rjmIrbxO7b9GImbSOdZQp6fQXkVTOl1zJl30JVZlD+B0gEonQmvBr52sUi0QbR6C7vxIy5aB3GAgEjyTCBAqd5To/1xzNh7+FOfoPyx5K2chlziFrbxK9FfXyLhGhI3QWxmFAeIBuVVUMEk/vnPMGeuoLzyycgl2sJu3yFa+Qq396RHetqcVKAMuvga/vSnmNF5lH/2yKzUmUu0Qbx1h3jtG1x76ZSEKSSQGNdeort6C1EKb3QelRtNzTrICBdiGw+I17/Hthb/A3HabGuRuH4b23gALkz5+pG7zjPi7bvY5sJLI+5voPEAFUygC+fQxTf640nkYol37uGaD0G6L50c6eKaD4l37qUSVye+bVzjPm7/6RAUlQiIDlAmnwiKDnqiojJXuv2nuMZ9pLvdH/MAbHMBiTaHBqDzk5jqNbzau+jCDOgirr1EvH6beOeXoRgSbWKbC3i1sQNy11rERVtDiGfwJj8mN/4heJW+gunKZXL5GVQwTnftWyRaHxx9tIVrLULteoIJ4MI6xO1s8srbmJErqGCip99JuiiTR+enMNU5zJnLmRjYDtLdPcAEkHgXOXQNjpAXLqAL5zP85zL9AOI6qTPX/+4IiTMXqlxloEwCKFNAecVMDFyUSHWKXOWGViBseEQkUiZd6JXZjB0mt+N5cpUbSQ0eu+nO37jOymB/uIHr1IdwF1HB2GHyKkrnMxfa5h9Jpg7y797H7v6WiYHyULrQ/+kBmPIs9pAAHDa394Tuyte4aBPv7Dw6PwW6gGs/Jt78gXjzZ1y4ms3t1zDl2TS5rsyi/Rqu/WjwSomxe8u47g7x5k+9e24Qu4dEW0i8O7Tma7+GrhwmDybR5UuovSUkHCwSuBAJ60iYfbbHmQrG0eVL6GDyYDPJZ4CpXk1eSUbb88KmDKY8i6lePZrtAKZ0EVOdGyoUL2K6cD5RwNLF9Hj/myliRufwJm4kjd9LMuWP4Y3fwIzOgUmLUEpZdDBJbuwD/OmbKFNiWJkcQosyJfzpm+TG30+ddX/G0e5VkF5Gh8tf9ArOCzSQXongwud4teuoXJXjAjm+bxeH2Bau/Sfd+nfY7btId+dktLkqZvQauYmP0KWLKFMeKN2D/zQg4CJcZxW3v4xrL+Faj3Cdld7dThoH5ddQpojOT6PLb6FLbyYVMD8F2j824hOQP2cuRKItXFhP6rGLENtKAEwZtI/KjaCDiaQ/H1InTkf+imxIHX219g8BW8x3YQCFzAAAAABJRU5ErkJggg==',
                  name: '充值中心',
                }
              ],
              selectedMode: false,
              itemWidth: 14, // 设置宽度
              itemHeight: 14, // 设置高度
            },
            series: [{
              name: '消费情况',
              type: 'pie',
              radius: ['50%', '70%'],
              center: ['40%', '50%'],
              avoidLabelOverlap: false,
              label: {
                show: true,
                formatter: `消费订单总数\n${this.numberTotal}`,
                position: 'center',
                lineHeight: 27,
                color: '#15171A',
                fontSize: 18
              },
              color: ['#7795F6', '#F8868E', '#F3C445'],
              data: this.proportainNumber
            }]
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
        tagMethod(i) {
          this.activeIndex = i
          let num = 0
          if(i === 1) {
            num = 3
          } else if (i === 2) {
            num = 2
          } else if (i === 3) {
            num = 1
          }
          console.log(num)
          this.PointFlowSummary(num)
        }
      }
    }
  </script>
  
  <style scoped>
    .homeContainer >>> .el-loading-spinner .path {
      stroke: #FF7E00;
    }
    .noDataBox {
      height: 120px;
      line-height: 120px;
      margin-bottom: 30px;
      text-align: center;
    }
    .homeContainer {
      height: 100%;
    }
    .settingBox {
      height: 100%;
      padding: 30px;
      text-align: left;
      background: #fff;
      box-sizing: border-box;
    }
    .settingBox .header {
      margin-bottom: 24px;
      padding-bottom: 10px;
      border-bottom: 1px solid #EDF0FC;
    }
    .settingBox .header span {
      font-size: 14px;
      color: #909399;
    }
    .settingBox .one-button {
      background: #FF7E00;
      color: #fff;
      border: 1px solid #FF7E00;
    }
    .settingBox .two-button:hover {
      background: #fff;
      color: #FF7E00;
      border: 1px solid #FF7E00;
    }
    .settingBox .iconSetting {
      position: relative;
      display: inline-block;
      width: 188px;
      height: 90px;
      margin-bottom: 20px;
      padding: 0 20px 0 80px;
      font-size: 14px;
      box-sizing: border-box;
      border: 1px solid #DCDFEB;
    }
    .iconSetting img {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      width: 48px;
    }
    .iconSetting span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .iconSetting i {
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 20px;
      color: #fc4848;
      cursor: pointer;
    }
    .iconRight i {
      color: #4c80fa;
    }
    .homeBox {
      padding-bottom: 20px;
      text-align: left;
    } 
    .title {
      font-size: 18px;
      color: #303133;
      text-align: left;
    }
    .overview {
      margin: 20px 0 32px;
    }
    .viewBox {
      background: #fff;
      padding: 20px 30px 25px!important;
    }
    .viewBox .header {
      display: flex;
      height: 40px;
      line-height: 40px;
      justify-content: space-between;
      /* padding-bottom: 10px; */
      border-bottom: 1px solid #EDF0FC;
    }
    .viewBox .amount {
      margin-left: 5px;
      font-size: 14px;
      color: #4C80FA;
      cursor: pointer;
    }
    .viewBox .stage {
      display: inline-block;
      padding: 0 8px;
      font-size: 14px;
      color: #909399;
      cursor: pointer;
    }
    .viewBox .tagActive {
      color: #FF7E00;
    }
    .content {
      height: 61px;
      padding-top: 20px;
    }
    .content .number {
      font-size: 28px;
    }
    .content .trend {
      margin-left: 10px;
      font-size: 14px;
      color: #00A870;
    }
    .content .bottom {
      margin-top: 10px;
      font-size: 14px;
      color: #606266;
    }
    .selectBox {
      position: relative;
      top: -5px;
    }
    .imgContent div {
      display: inline-block;
      font-size: 14px;
      color: #909399;
    }
    .imgContent .number {
      font-size: 26px;
      color: #303133;
    }
    .numberBox {
      position: relative;
      top: -10px;
      margin-left: 10px;
    }
    .bottomBox {
      margin-top: 20px;
    }
    .iconBox {
      height: 354px;
      padding: 30px 42px 0;
      box-sizing: border-box;
      background: #fff;
    }
    .iconImg {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      font-size: 14px;
    }
    .iconImg img {
      margin-bottom: 5px;
    }
    .proceeBox {
      margin: 20px 0 12px;
    }
    .processLeft {
      height: 202px;
      margin-bottom: 20px;
      overflow: hidden;
      background: #fff;
    }
    .processLeft .left {
      width: 164px;
      height: 100%;
      float: left;
      padding-top: 24px;
      text-align: center;
      /* background: url('../../assets/img/proceed_bg.png'); */
    }
    .processLeft .left.cj {
      background: url('../../assets/img/新春.png');
    }
    .processLeft .left.fn {
      background: url('../../assets/img/女神.png');
    }
    .processLeft .left.dw {
      background: url('../../assets/img/端午.png');
    }
    .processLeft .left.zq {
      background: url('../../assets/img/中秋.png');
    }
    .processLeft .left.zn {
      background: url('../../assets/img/周年庆.png');
    }
    .processLeft .left.sr {
      background: url('../../assets/img/生日.png');
    }
    .processLeft .left.zdy {
      background: url('../../assets/img/自定义.png');
    }
    .processLeft .one {
      margin-top: 8px;
      font-size: 14px;
      color: #909399;
    }
    .processLeft .right {
      float: left;
      width: calc(100% - 164px);
      height: 100%;
      padding: 40px 40px 0;
      overflow: hidden;
      box-sizing: border-box;
    }
    .processLeft .box {
      float: left;
      font-size: 14px;
      color: #909399;
    }
    .processLeft .box span:nth-child(3) {
      display: inline-block;
      margin-top: 10px;
      font-size: 24px;
      color: #303133;
    }
    .processLeft .box1,
    .processLeft .box2 {
      width: 28%;
    }
    .processLeft .box3 {
      width: 44%;
    }
    .processLeft .btnBox {
      display: inline-block;
      height: 28px;
      line-height: 28px;
      margin: 30px 30px 0 0;
      padding: 0 10px;
      font-size: 14px;
      border: 1px solid #DCDFEB;
      border-radius: 3px;
      cursor: pointer;
    }
    .btnBox img {
      position: relative;
      top: 3px;
    }
    .echartTitle img {
      position: relative;
      margin-left: 10px;
      top: 3px;
    }
    .echartTitle span {
      font-size: 14px;
      color: #909399;
      cursor: pointer;
    }
    .echartBox {
      margin-top: 20px;
    }
    /* .echartBox .left {
      padding-top: 30px;
      background: #fff;
    } */
    .echartBox #one,
    .echartBox #two,
    .echartBox #three,
    .echartBox #four,
    .echartBox #five,
    .echartBox #six,
    .echartBox #seven {
      height: 420px;
    }
    .echartBox .bottom {
      margin-bottom: 20px;
      padding-top: 30px;
      background: #fff;
    } 
  </style>